<template>
    <All model="HpList" :immediate="true" ref="hpListsRef" v-slot="{data:hpLists}" :query="hpListsFilter" @loading:change="(event) => loading = event">
        <SectionWrapper>
            <div class="w-full px-1 sm:px-2 xl:px-5">
                <Header :title="title"/>
                <div class="py-2 w-full flex flex-row items-between justify-center">
                    <perPageSelector @changeOption="changePerPage"/>
                    <div class="w-auto sm:w-1/3 flex flex-row flex-grow justify-center sm:justify-center sm:px-6">
<!--                        <button @click="refreshDataTable" class="p-2 mr-2 w-24 bg-bo-form shadow-md hover:text-blue-600 rounded-md focus:outline-none">Refrescar</button>-->
                        <Request model="HpList" action="syncUp" @success="onSuccessSyncUp" @error="onErrorSyncUp" ref="SyncUpHpListsRef"/>
                        <button @click="syncUp" class="p-2 mr-2 w-24 bg-bo-form shadow-md hover:text-blue-600 rounded-md focus:outline-none">Sincronizar</button>
                    </div>
                    <searcher @changeText="changeText"/>
                </div>
                <Table
                    :data="hpLists.data" :fields="fields" :fieldBy="hpListsFilter.field"
                    :direction="hpListsFilter.direction" :loading="loading"
                    @changeField="changeField" @changeDirection="changeDirection"/>
                <paginate v-if=hpLists.meta :meta=hpLists.meta @changePage="changePage"/>
                <PaginateTextInfo v-if="hpLists.meta" :meta="hpLists.meta" class="flex-auto text-center sm:hidden"/>
                <PaginateMobile :links="hpLists.links" @changePage="changePage" class="sm:hidden"/>
            </div>
        </SectionWrapper>
    </All>
</template>

<script>
import {All, Request} from '@/api/components';
import PerPageSelector from "@/pages/inside/shared/datatables/PerPageSelector";
import Searcher from "@/pages/inside/shared/datatables/Searcher";
import Table from "@/pages/inside/sections/hplists/Table";
import Paginate from "@/pages/inside/shared/datatables/Paginate";
import PaginateMobile from "@/pages/inside/shared/datatables/PaginateMobile";
import Header from "@/pages/inside/shared/datatables/Header";
import SectionWrapper from "@/pages/inside/shared/slots/SectionWrapper";
import PaginateTextInfo from "@/pages/inside/shared/datatables/PaginateTextInfo";
import {actions, state} from "@/store";
export default {
name: "HpLists",
    components:{
        All, SectionWrapper, Header, Paginate, PaginateMobile, PerPageSelector, Searcher, Table, Request, PaginateTextInfo
    },
    data() {
        return {
            title: 'Listas HP',
            entity: 'Lista HP',
            loading: false,
            hpListsFilter: {
                page: null,
                perPage: state.visibleRowsQtyInTable,
                field: 'name',
                direction: 'asc',
                text: null
            },
            fields,
        }
    },
    methods: {
        refreshDataTable(keepSelection = false) {
            if (!keepSelection) {
                actions.setElSelected(false);
            }
            this.$refs.hpListsRef.request();
        },

        changePage(page){
            this.hpListsFilter.page = page;
            this.refreshDataTable();
        },

        changePerPage(option){
            this.hpListsFilter.perPage = option;
            this.hpListsFilter.page = 1;
            this.refreshDataTable();
        },

        changeField(field){
            this.hpListsFilter.field = field;
            this.refreshDataTable();
        },

        changeDirection(direction){
            this.hpListsFilter.direction = direction;
            this.refreshDataTable();
        },

        changeText(text){
            this.hpListsFilter.page = null;
            this.hpListsFilter.text = text;
            this.refreshDataTable();
        },
        async syncUp() {
            await this.$refs.SyncUpHpListsRef.request(); // Para hacer la request
            await this.refreshDataTable();
        },
        onSuccessSyncUp(response) {
            this.response = JSON.parse(JSON.stringify(response));
            this.$notify(
                { group: "generic", title: "Info", text: this.response.data.message, type: 'info'},
                this.millis
            );
        },
        onErrorSyncUp(errors) {
            this.toastErrors(errors)
        }
    }
};

const fields = [
    {
        label: 'Lista',
        key: 'name'
    },
    {
        label: 'Contactos',
        key: 'size'
    },
    {
        label: 'Creado',
        key: 'created_at'
    },
    {
        label: 'Actualizado',
        key: 'updated_at'
    },
];
</script>

<style scoped>

</style>